.outside {
  background-color: rgba(55, 137, 187, 0.84);
  min-height: 100vh;
}

.app {
  display: flex;
  align-content: center;
  justify-content: center;
}

.card {
  margin: 1rem;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 77%) 1px 7px 30px -10px;
}

.update-time {
  font-size: 0.7rem;
  color: #797979;
}

.numbers {
  font-weight: bold;
}

.float-right {
  float: right;
}

.font-weight-bold {
  font-weight: bold;
}

.logo {
  width: 4rem;
  height: auto;
}

.input-group input {
  text-align: right;
  font-weight: bold;
}
